<!--
 * @Auth: linjituan
 * @Date: 2021-11-24 09:35:02
 * @LastEditors: linjituan
 * @LastEditTime: 2021-11-24 16:02:25
-->
<template>
  <vxe-modal
    v-model="alarmVisible"
    width="60%"
    height="80%"
    class="device-alarm-modal"
    title="报警事件信息"
    @close="handleCloaseCamera"
    @hide="handleCloaseCamera"
    show-zoom
    resize
    :lock-view="false"
    :mask="false"
    :esc-closable="true"
    :position="position"
    transfer
    ref="alarmRef"
  >
    <a-descriptions bordered v-if="info.deviceId">
      <a-descriptions-item label="摄像头故障">
        {{ info.cameraFault }}
      </a-descriptions-item>
      <a-descriptions-item label="上班打卡">
        {{ info.clockIn }}
      </a-descriptions-item>
      <a-descriptions-item label="下班打卡">
        {{ info.clockOut }}
      </a-descriptions-item>
      <a-descriptions-item label="碰撞预警">
        {{ info.collisionWarn }}
      </a-descriptions-item>
      <a-descriptions-item label="当天累计驾驶超时">
        {{ info.cumulativeDrivingTimeout }}
      </a-descriptions-item>
      <a-descriptions-item label="危险预警">
        {{ info.dangerWarning }}
      </a-descriptions-item>
      <a-descriptions-item label="设备时间">
        {{ info.deviceTime }}
      </a-descriptions-item>
      <a-descriptions-item label="违规行驶报警">
        {{ info.driverAgainstRules }}
      </a-descriptions-item>
      <a-descriptions-item label="路段行驶时间不足/过长">
        {{ info.drivingTimeIncorrect }}
      </a-descriptions-item>
      <a-descriptions-item label="紧急报警">
        {{ info.emergencyAlarm }}
      </a-descriptions-item>
      <a-descriptions-item label="疲劳驾驶报警">
        {{ info.fatigueDrivingAlarm }}
      </a-descriptions-item>
      <a-descriptions-item label="疲劳驾驶预警">
        {{ info.fatigueDrivingWarn }}
      </a-descriptions-item>
      <a-descriptions-item label="GNSS天线未接或被剪断">
        {{ info.gnssConnectFault }}
      </a-descriptions-item>
      <a-descriptions-item label="GNSS模块发生故障">
        {{ info.gnssModuleFault }}
      </a-descriptions-item>
      <a-descriptions-item label="GNSS天线短路">
        {{ info.gnssShortCircuit }}
      </a-descriptions-item>
      <a-descriptions-item label="道路运输证IC卡模块故障">
        {{ info.icModuleFault }}
      </a-descriptions-item>
      <a-descriptions-item label="车辆非法位移">
        {{ info.illegalDisplacement }}
      </a-descriptions-item>
      <a-descriptions-item label="车辆非法点火">
        {{ info.illegalIgnition }}
      </a-descriptions-item>
      <a-descriptions-item label="非法开门报警">
        {{ info.illegalOpeningTheDoor }}
      </a-descriptions-item>
      <a-descriptions-item label="进出区域报警">
        {{ info.inArea }}
      </a-descriptions-item>
      <a-descriptions-item label="终端LCD或显示器故障">
        {{ info.lcdFault }}
      </a-descriptions-item>
      <a-descriptions-item label="车辆油量异常">
        {{ info.oilFault }}
      </a-descriptions-item>
      <a-descriptions-item label="进出路线报警">
        {{ info.outLine }}
      </a-descriptions-item>
      <a-descriptions-item label="超速报警">
        {{ info.overSpeedAlarm }}
      </a-descriptions-item>
      <a-descriptions-item label="超速预警">
        {{ info.overSpeedWarn }}
      </a-descriptions-item>
      <a-descriptions-item label="终端主电源掉电">
        {{ info.powerFault }}
      </a-descriptions-item>
      <a-descriptions-item label="终端主电源欠压">
        {{ info.powerUnderpressure }}
      </a-descriptions-item>
      <a-descriptions-item label="右转盲区异常报警">
        {{ info.rightTurnBlindArea }}
      </a-descriptions-item>
      <a-descriptions-item label="侧翻预警">
        {{ info.rollOverWarn }}
      </a-descriptions-item>
      <a-descriptions-item label="路线偏离报警">
        {{ info.routeDeviation }}
      </a-descriptions-item>
      <a-descriptions-item label="车辆被盗(通过车辆防盗器)">
        {{ info.stolenVehicle }}
      </a-descriptions-item>
      <a-descriptions-item label="超时停车">
        {{ info.stopTimeout }}
      </a-descriptions-item>
      <a-descriptions-item label="胎压预警">
        {{ info.tirePressureWarning }}
      </a-descriptions-item>
      <a-descriptions-item label="TTS模块故障">
        {{ info.ttsFault }}
      </a-descriptions-item>
      <a-descriptions-item label="车辆VSS故障">
        {{ info.vssFault }}
      </a-descriptions-item>
    </a-descriptions>
    <div class="no-data-class" v-if="showNodata">未获取到事件报警信息</div>
  </vxe-modal>
</template>

<script>
import { alarmDeviceLocation } from '@/api/jt808/deviceLocation'
import { Descriptions } from 'ant-design-vue'
export default {
  components: {
    ADescriptions: Descriptions,
    ADescriptionsItem: Descriptions.Item
  },
  data() {
    return {
      info: {},
      alarmVisible: false,
      showNodata: false,
      position: {}
    }
  },
  methods: {
    open(record, left) {
      this.info = {}
      this.showNodata = false
      this.alarmEvent(record)
      this.alarmVisible = true
      if (left) {
        this.$nextTick(() => {
          this.position.left = left
        })
      }
    },
    // 事件警报
    alarmEvent(record) {
      alarmDeviceLocation({
        deviceId: record.deviceId, // 'test123456', // record.deviceId,
        deviceTime: record.deviceTime // '2021-11-19 07:23:59' // record.deviceTime
      }).then((response) => {
        if (response.data) {
          this.info = response.data
          for (const key in this.info) {
            if (typeof this.info[key] === 'boolean') {
              this.info[key] = this.info[key] ? '是' : '否'
            }
          }
        } else {
          this.showNodata = true
        }
      })
    },
    handleCloaseCamera() {
      this.alarmVisible = false
    }
  }
}
</script>

<style lang="less" scoped>
.device-alarm-modal {
  .ant-descriptions {
    height: auto;
  }
  .no-data-class {
    text-align: center;
    padding: 20px 0;
  }
}
</style>
