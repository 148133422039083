var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vxe-modal",
    {
      ref: "alarmRef",
      staticClass: "device-alarm-modal",
      attrs: {
        width: "60%",
        height: "80%",
        title: "报警事件信息",
        "show-zoom": "",
        resize: "",
        "lock-view": false,
        mask: false,
        "esc-closable": true,
        position: _vm.position,
        transfer: "",
      },
      on: { close: _vm.handleCloaseCamera, hide: _vm.handleCloaseCamera },
      model: {
        value: _vm.alarmVisible,
        callback: function ($$v) {
          _vm.alarmVisible = $$v
        },
        expression: "alarmVisible",
      },
    },
    [
      _vm.info.deviceId
        ? _c(
            "a-descriptions",
            { attrs: { bordered: "" } },
            [
              _c("a-descriptions-item", { attrs: { label: "摄像头故障" } }, [
                _vm._v(" " + _vm._s(_vm.info.cameraFault) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "上班打卡" } }, [
                _vm._v(" " + _vm._s(_vm.info.clockIn) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "下班打卡" } }, [
                _vm._v(" " + _vm._s(_vm.info.clockOut) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "碰撞预警" } }, [
                _vm._v(" " + _vm._s(_vm.info.collisionWarn) + " "),
              ]),
              _c(
                "a-descriptions-item",
                { attrs: { label: "当天累计驾驶超时" } },
                [_vm._v(" " + _vm._s(_vm.info.cumulativeDrivingTimeout) + " ")]
              ),
              _c("a-descriptions-item", { attrs: { label: "危险预警" } }, [
                _vm._v(" " + _vm._s(_vm.info.dangerWarning) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "设备时间" } }, [
                _vm._v(" " + _vm._s(_vm.info.deviceTime) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "违规行驶报警" } }, [
                _vm._v(" " + _vm._s(_vm.info.driverAgainstRules) + " "),
              ]),
              _c(
                "a-descriptions-item",
                { attrs: { label: "路段行驶时间不足/过长" } },
                [_vm._v(" " + _vm._s(_vm.info.drivingTimeIncorrect) + " ")]
              ),
              _c("a-descriptions-item", { attrs: { label: "紧急报警" } }, [
                _vm._v(" " + _vm._s(_vm.info.emergencyAlarm) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "疲劳驾驶报警" } }, [
                _vm._v(" " + _vm._s(_vm.info.fatigueDrivingAlarm) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "疲劳驾驶预警" } }, [
                _vm._v(" " + _vm._s(_vm.info.fatigueDrivingWarn) + " "),
              ]),
              _c(
                "a-descriptions-item",
                { attrs: { label: "GNSS天线未接或被剪断" } },
                [_vm._v(" " + _vm._s(_vm.info.gnssConnectFault) + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: "GNSS模块发生故障" } },
                [_vm._v(" " + _vm._s(_vm.info.gnssModuleFault) + " ")]
              ),
              _c("a-descriptions-item", { attrs: { label: "GNSS天线短路" } }, [
                _vm._v(" " + _vm._s(_vm.info.gnssShortCircuit) + " "),
              ]),
              _c(
                "a-descriptions-item",
                { attrs: { label: "道路运输证IC卡模块故障" } },
                [_vm._v(" " + _vm._s(_vm.info.icModuleFault) + " ")]
              ),
              _c("a-descriptions-item", { attrs: { label: "车辆非法位移" } }, [
                _vm._v(" " + _vm._s(_vm.info.illegalDisplacement) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "车辆非法点火" } }, [
                _vm._v(" " + _vm._s(_vm.info.illegalIgnition) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "非法开门报警" } }, [
                _vm._v(" " + _vm._s(_vm.info.illegalOpeningTheDoor) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "进出区域报警" } }, [
                _vm._v(" " + _vm._s(_vm.info.inArea) + " "),
              ]),
              _c(
                "a-descriptions-item",
                { attrs: { label: "终端LCD或显示器故障" } },
                [_vm._v(" " + _vm._s(_vm.info.lcdFault) + " ")]
              ),
              _c("a-descriptions-item", { attrs: { label: "车辆油量异常" } }, [
                _vm._v(" " + _vm._s(_vm.info.oilFault) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "进出路线报警" } }, [
                _vm._v(" " + _vm._s(_vm.info.outLine) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "超速报警" } }, [
                _vm._v(" " + _vm._s(_vm.info.overSpeedAlarm) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "超速预警" } }, [
                _vm._v(" " + _vm._s(_vm.info.overSpeedWarn) + " "),
              ]),
              _c(
                "a-descriptions-item",
                { attrs: { label: "终端主电源掉电" } },
                [_vm._v(" " + _vm._s(_vm.info.powerFault) + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: "终端主电源欠压" } },
                [_vm._v(" " + _vm._s(_vm.info.powerUnderpressure) + " ")]
              ),
              _c(
                "a-descriptions-item",
                { attrs: { label: "右转盲区异常报警" } },
                [_vm._v(" " + _vm._s(_vm.info.rightTurnBlindArea) + " ")]
              ),
              _c("a-descriptions-item", { attrs: { label: "侧翻预警" } }, [
                _vm._v(" " + _vm._s(_vm.info.rollOverWarn) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "路线偏离报警" } }, [
                _vm._v(" " + _vm._s(_vm.info.routeDeviation) + " "),
              ]),
              _c(
                "a-descriptions-item",
                { attrs: { label: "车辆被盗(通过车辆防盗器)" } },
                [_vm._v(" " + _vm._s(_vm.info.stolenVehicle) + " ")]
              ),
              _c("a-descriptions-item", { attrs: { label: "超时停车" } }, [
                _vm._v(" " + _vm._s(_vm.info.stopTimeout) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "胎压预警" } }, [
                _vm._v(" " + _vm._s(_vm.info.tirePressureWarning) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "TTS模块故障" } }, [
                _vm._v(" " + _vm._s(_vm.info.ttsFault) + " "),
              ]),
              _c("a-descriptions-item", { attrs: { label: "车辆VSS故障" } }, [
                _vm._v(" " + _vm._s(_vm.info.vssFault) + " "),
              ]),
            ],
            1
          )
        : _vm._e(),
      _vm.showNodata
        ? _c("div", { staticClass: "no-data-class" }, [
            _vm._v("未获取到事件报警信息"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }